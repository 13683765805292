<template>
    <div :class="[$style.menu, $_setStyle]">
        <div :class="[$style.menu__block, $style.side]">
        </div>
        <div :class="[$style.menu__block, $style.main]">
            <div :class="$style.menu__block__logo">
                <Logo
                    name="menu"
                />
            </div>
            <nav>
                <ul>
                    <template v-for="(key, index) in menu" >
                        <li v-if="$store.getters.getManagements[key].use" :key="`menu${index}`">
                            <router-link :active-class="$style[active]" :to="{ 'name': `${key}_list` }">
                                <Icon
                                    color="#fff"
                                    :name="key"
                                />
                                <span>
                                    {{ `${management[key].title}${textset[management[key].textset].menu}` }}
                                </span>
                            </router-link>
                        </li>
                    </template>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
    "use strict";

    import management from "@/settings/management.js";
    import textset from "@/settings/textset.js";

    import Icon from "@/components/Icon.vue";
    import Logo from "@/components/Logo.vue";

    import style from "@/mixins/style.js";

    // menu.moduleshop = {
    //     "link": "moduleshop",
    //     "text": "Module Shop",
    // };

    export default {
        "components": {
            Icon,
            Logo,
        },
        "mixins": [
            style,
        ],
        data() {
            return {
                "active": "isCurrent",
                "management": management,
                "menu": this.$store.getters.getDisplayOrders["/dashboard"],
                "textset": textset,
            };
        },
        created() {
            this.$store.watch(
                (state, getters) => getters.getDisplayOrders,
                () => {
                    if (this.menu.length === 0) {
                        this.menu = this.$store.getters.getDisplayOrders["/dashboard"];
                    }
                }
            );
        },
    };
</script>

<style lang="scss" module>
    .menu {
        @include scrollbar;
        background: #222;
        color: #fff;
        display: flex;
        height: 100vh;
        left: 0;
        overflow: auto;
        position: fixed;
        top: 0;
        width: 256px;
        z-index: 999;
        @include sp {
            display: none;
        }
        &__block {
            &.side {
                background: #666;
                height: 100%;
                width: 16px;
            }
            &.main {
                flex: 1;
                > nav {
                    padding: 16px;
                    > ul {
                        > li {
                            > a {
                                align-items: center;
                                background-color: rgba(#fff, 0);
                                border-radius: 8px;
                                color: #fff;
                                display: flex;
                                font-size: 16px;
                                margin: 8px 0 0;
                                opacity: .6;
                                padding: 16px;
                                position: relative;
                                width: 100%;
                                &:hover {
                                    opacity: 1;
                                }
                                &.isCurrent {
                                    background-color: rgba(#fff, .08);
                                    opacity: 1;
                                }
                                > span {
                                    margin: 0 0 -3px 1em;
                                }
                            }
                        }
                    }
                }
            }
            &__logo {
                width: 100%;
            }
        }
    }

    @mixin style ($color) {
        &.menu {
            .menu {
                &__block {
                    &.side {
                        background: $color;
                    }
                }
            }
        }
    }

    .isYellow {
        @include style ($yellow);
    }

    .isPink {
        @include style ($pink);
    }

    .isGreen {
        @include style ($green);
    }
</style>
