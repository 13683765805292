"use strict";

const texts = {
    "data": {
        "menu": "管理",
        "list": "一覧",
        "read": "取得",
        "create": "追加",
        "update": "変更",
        "detail": "詳細",
        "delete": "削除",
        "history": "履歴",
        "status": {
            "return": "返却済み",
        },
    },
    "master": {
        "menu": "管理",
        "list": "一覧",
        "read": "取得",
        "create": "登録",
        "update": "編集",
        "detail": "詳細",
        "delete": "削除",
        "history": "履歴",
    },
};

export default texts;
