<template>
    <div id="app" class="wrapper">
        <Menu />
        <div class="content">
            <Header
                name="dashboard"
            />
            <router-view />
        </div>
    </div>
</template>

<script>
    "use strict";

    import Header from "@/components/TheHeader.vue";
    import Menu from "@/components/TheMenu.vue";

    import setting from "@/mixins/setting.js";

    export default {
        "components": {
            Header,
            Menu,
        },
        "mixins": [
            setting,
        ],
    };
</script>

<style lang="scss">
    $content-text-color: #4c4c4c;

    input,
    select,
    textarea {
        border-radius: 8px;
        width: 100%;
    }

    input,
    select,
    button,
    textarea {
        color: $content-text-color;
    }

    .wrapper {
        width: 100%;
    }

    .content {
        background: #f9f9f9;
        color: $content-text-color;
        min-height: 100vh;
        padding-left: 256px;
        position: relative;
        @include sp {
            padding-left: 0;
        }
    }

    .dashboard {
        overflow: auto;
        padding: 32px;
        @include sp {
            padding: 24px 18px;
        }
        h1 {
            font-size: 32px;
            @include sp {
                font-size: 24px;
            }
        }
        h2 {
            font-size: 24px;
            @include sp {
                font-size: 16px;
            }
        }
        h3 {
            font-size: 18px;
            font-weight: bold;
        }
        a {
            &.arrow {
                &::after {
                    content: " ▶";
                }
            }
        }
        input[type="file"] {
            width: auto;
        }
        input {
            &:disabled {
                background-color: rgba(255, 255, 255, 0);
            }
        }
        select {
            &:disabled {
                background-color: rgba(255, 255, 255, 0);
            }
        }
        table {
            line-height: 1.4;
            width: 100%;
            tr {
                @include sp {
                    background: #fff;
                    border: 1px solid #ddd;
                    border-left: 1px solid $theme-color;
                    display: block;
                    margin: 0 0 16px;
                    padding: 12px;
                }
                &:first-child {
                    td {
                        border-top: none;
                    }
                }
                &:hover {
                    td {
                        background: #f3f3f3;
                        color: $content-text-color;
                    }
                }
                .isWrap {
                    white-space: normal;
                }
                .isDiscard {
                    color: #f00;
                }
            }
            th {
                border-bottom: 1px solid #333;
                font-weight: normal;
                padding: 8px 12px;
                vertical-align: bottom;
                white-space: nowrap;
                @include sp {
                    border: none;
                    display: block;
                    padding: 6px;
                    &:last-child {
                        display: none;
                    }
                }
            }
            td {
                background: #fff;
                border-top: 1px solid #ddd;
                padding: 8px 12px;
                vertical-align: middle;
                white-space: nowrap;
                @include sp {
                    border: none;
                    display: block;
                    padding: 3px;
                    white-space: normal;
                }
                img {
                    max-width: 80px;
                }
            }
            input[type="checkbox"] {
                + label {
                    margin-bottom: 4px;
                    margin-top: 4px;
                }
            }
        }
        .pagetitle {
            margin-bottom: 48px;
            @include sp {
                margin-bottom: 28px;
            }
        }
        .date {
            position: relative;
            top: 3px;
            margin: 0 12px 0 0;
            font-size: 24px;
        }
    }
</style>
